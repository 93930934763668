import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { servicesLinks } from 'components/Layout/navigation';
import Service from 'components/Service_Ecommerce';
import { ImgGatsbyBackground } from 'components/wrappers';
import Icon1 from 'images/icon1_ecommerce2.png';
import Icon2 from 'images/icon2_ecommerce2.png';
import Icon3 from 'images/icon3_ecommerce2.png';
import Step1 from 'images/steps/ecommerce-fulfillment/shipping.png';
import Step2 from 'images/steps/ecommerce-fulfillment/stocking.png';
import Step3 from 'images/steps/ecommerce-fulfillment/origin-fulfillment.png';
import Step4 from 'images/steps/ecommerce-fulfillment/express-shipping.png';
import Step5 from 'images/steps/ecommerce-fulfillment/pickup.png';

const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query ImgEcommerceQuery {
      bg: file(relativePath: { eq: "Ecommerce_Fulfillment.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.bg.childImageSharp.fluid} alt="shipkoo global ecommerce product fulfillment service" />;
  const IconCardOne = () => <img src={Icon1} />;
  const IconCardTwo = () => <img src={Icon2} />;
  const IconCardThree = () => <img src={Icon3} />;
  const intl = useIntl()
  return (
    <Service
      BackgroundImage={BackgroundImage}
      IconCardOne={IconCardOne}
      IconCardTwo={IconCardTwo}
      IconCardThree={IconCardThree}
      navigationTabs={servicesLinks}
      activeTab={servicesLinks[2].value}
      description={
        <>
          {intl.formatMessage({ id: "ecommerce-fulfillment-header-description" })} 
          <br/><br/>
          {intl.formatMessage({ id: "ecommerce-fulfillment-header-description-1" })}
        </>
      }
      header={
        <>
          {intl.formatMessage({ id: "ecommerce-fulfillment-sub-header-description" })}
        </>
      }
      about={intl.formatMessage({ id: "ecommerce-fulfillment-header" })}
      cardsTitle={[
        'Platform Integrations',
        'Global Fulfillment',
        'Express Shipping',
      ]}
      cards={[
        'A robust fulfillment system, in which a one-click order syncs with 100+ marketplaces and platforms',
        'Fulfillment centers and bonded facilities in China, the UK, the US, Japan, Korea, Poland and Vietnam  means that you’re always close to a Shipkoo hub',
        'On-demand service at fast speeds: Shipkoo sends out deliveries within 24 hours of the order being placed',
      ]}
      numSteps={intl.formatMessage({ id: "ecommerce-fulfillment-how-it-works-explained" })}
      timelineItems={[
        {
          titleStep: 'Step 1',
          title: 'Send inventory',
          description:
            'Ship your products from the manufacturer to Shipkoo’s Ecommerce Distribution Center',
          image: Step1,
          alt: 'shipkoo ecommerce order shipping fulfillment service',
          titleStepZH: '第一步',
          titleZH: '运输',
          descriptionZH: '将您的产品从制造商运送到 Shipkoo 的电子商务配送中心',
          titleStepKO: '1단계',
          titleKO: '재고 보내기',
          descriptionKO: '제조업체에서 Shipkoo의 전자상거래 유통센터로 제품을 배송',
          titleStepJP: 'ステップ1',
          titleJP: 'インベントリの送信',
          descriptionJP: 'メーカーからShipkooのeコマース流通センターに製品を発送します',
        },
        {
          titleStep: 'Step 2',
          title: 'Intake & Putaway',
          description:
            'Shipkoo processes your goods, inspects them for damage, and scans the info into our inventory management system',
          image: Step2,
          alt: 'shipkoo best ecommerce fulfillment service thorough product inspection and stocking',
          titleStepZH: '第二步',
          titleZH: '入库上架',
          descriptionZH: 'Shipkoo酷运 处理您的货物，检查货物是否损坏，并将信息扫描到我们的库存管理系统中',
          titleStepKO: '2단계',
          titleKO: '섭취 & 퍼트어웨이',
          descriptionKO: 'Shipkoo는 당신의 상품을 처리하고, 손상 여부를 검사하고, 우리의 재고 관리 시스템에 정보를 스캔한다.',
          titleStepJP: 'ステップ2',
          titleJP: '摂取と保管',
          descriptionJP: 'Shipkooは商品を処理し、損傷がないか検査し、情報をスキャンして在庫管理システムに取り込みます',
        },
        {
          titleStep: 'Step 3',
          title: 'Fulfillment',
          description:
            'Auto-sync new orders to the Shipkoo fulfillment system with multi-platform integrations. As orders come in, Shipkoo processes your packages and sends them to customers wherever they are',
          image: Step3,
          alt: 'shipkoo best ecommerce origin fulfillment service',
          titleStepZH: '第三步',
          titleZH: '仓配',
          descriptionZH: '通过多平台集成将新订单自动同步到Shipkoo酷运操作系统。当订单进入时，Shipkoo酷运会处理您的包裹并将其发送给世界各地的客户。',
          titleStepKO: '3단계',
          titleKO: '이행',
          descriptionKO: '멀티연동 플랫폼으로 Shipkoo 이행 시스템에 신규 주문 자동 동기화 주문이 들어오면 Shipkoo가 패키지를 처리하여 고객이 어디에 있든 고객에게 발송',
          titleStepJP: 'ステップ3',
          titleJP: 'フルフィルメント',
          descriptionJP:'マルチプラトフォーム一覧により、新しい注文をShipkooフルフィルメントシステムに自動同期します。注文が届くと、Shipkooはパッケージを処理し、どこにいても顧客に送ります',
        },
        {
          titleStep: 'Step 4',
          title: 'Tracking',
          description:
            'Shipkoo automatically generates tracking numbers and makes them available to the end-customer, who have full visibility on the status of their package',
          image: Step4,
          alt: 'shippcoo ecommerce order fulfillment express shipping service',
          titleStepZH: '第四步',
          titleZH: '快递运输追踪',
          descriptionZH: 'Shipkoo酷运 自动生成跟踪编号并将其通过电子邮件发送给客户，客户可以完全了解其包裹的状态',
          titleStepKO: '4단계',
          titleKO: '추적',
          descriptionKO: 'Shipkoo는 자동으로 추적 번호를 생성하여 패키지 상태를 완벽하게 파악할 수 있는 최종 고객에게 제공.',
          titleStepJP: 'ステップ4',
          titleJP: '追跡',
          descriptionJP: 'Shipkooは自動的に追跡番号を生成し、荷物の状態を完全に把握できるエンドユーザーに提供します。',
        },
        {
          titleStep: 'Step 5',
          title: 'Delivery',
          description: 'The product is delivered to the end-customer',
          image: Step5,
          alt: 'shipkoo ecommerce order fulfillment service product pickup',
          titleStepZH: '第五步',
          titleZH: '收货',
          descriptionZH: '客户收到包裹',
          titleStepKO: '5단계',
          titleKO: '배달',
          descriptionKO: '제품이 최종 고객에게 배송됨.',
          titleStepJP: 'ステップ5',
          titleJP: '配送',
          descriptionJP: '製品がエンドユーザに配送される',
        },
      ]}
    />
  );
};

export default ServicesPage;
