import React from 'react';
import styled from 'styled-components';

import { mediaMin } from 'common/styledMixins';
import { zIndex, color, breakpoint } from 'common/styledVars';
import { HeaderMedium, BaseP, MediumText } from 'components/typography';
import Cards from './Cards';
import { RelativeWrapper } from 'components/wrappers';
import { WidthWrapper, ImgGatsbyBackground } from 'components/wrappers';
import ArrowButton from 'components/Button/ArrowButton';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import ContactFormHeader from "components/pages/Contact/ContactFormHeaderEccomerce"

const Wrapper = styled(RelativeWrapper)`
  ${ImgGatsbyBackground} {
    bottom: 0;
    height: auto;
  }
  
  @media ${mediaMin(breakpoint.desktop)} {
    ${WidthWrapper} {
      position: relative;
    }
    ${ImgGatsbyBackground} img {
      object-position: 0 -175px !important;
    }
    ${ImgGatsbyBackground} {
      bottom: -290px;
      height: auto;
    }
  }
`;

const MainTitle = styled.h1`
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 16px;
  }
`;

const SubTitle = styled.h2`
    margin: 40px 0 20px;
    color: #ffffff;
    font-size: 28px;
    font-weight: 500;
  
  @media ${mediaMin(breakpoint.desktop)} {
      font-size: 36px;
  }
`;

const Background = styled.div`
  z-index: ${zIndex.body + 1};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  mix-blend-mode: multiply;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(113deg, #8bdfbd, #00b399);
`;

const Container = styled.div`
  // max-width: 950px;
  padding: 32px 0 64px;
  @media ${mediaMin(breakpoint.desktop)} {
    position: relative;
    margin: 80px 0 80px;
    padding: 80px 60px 80px 60px;
    display: flex;
    align-items: center;
    column-gap: 30px;
}
  }
  @media only screen and (max-width:1024px) {
    padding: 80px 30px 40px 30px;
  }
  @media only screen and (max-width:768px) {
    padding: 32px 0 30px;
  }
`;

const Content = styled.div`
  max-width: 600px;
  display: block;
  color: ${color.white};
  z-index: ${zIndex.body + 5};
  position: relative;
  ${BaseP} {
    color: ${color.white};
  }
  ${MediumText} {
    color: ${color.white};
    max-width: 625px;
  }
  ${HeaderMedium} {
    margin: 40px 0 20px;
    color: ${color.white};
  }
  ${ArrowButton} {
    margin-top: 80px;
  }
  @media only screen and (max-width:981px) {
    ${ArrowButton} {
      margin-top: 35px;
    }
  }
  @media only screen and (max-width:768px) {
    max-width: 100%;
    margin-top: 20px;
  }
`;

interface OwnProps {
  about: string;
  header: React.ReactElement;
  description: React.ReactElement;
  cards: string[];
  BackgroundImage: React.ElementType;
  handleClick(): void;
  cardsTitle: string[];
  IconCardOne: React.ElementType;
  IconCardTwo: React.ElementType;
  IconCardThree: React.ElementType;
}

const Header = ({ about, header, description, cards, BackgroundImage, handleClick, cardsTitle, IconCardOne, IconCardTwo, IconCardThree }: OwnProps) => {
  const intl = useIntl()
  return (
    <>
      <Wrapper>
        <WidthWrapper style={{ zIndex: 'inherit' }}>
          <Container>
            <Background />
            <Content>
              <MainTitle>{about}</MainTitle>
              <SubTitle>{header}</SubTitle>
              <MediumText>{description}</MediumText>
              <ArrowButton onClick={handleClick}>{intl.formatMessage({ id: "ecommerce-fulfillment-see-the-whole-process-button" })}</ArrowButton>
            </Content>
            <Content>
              <ContactFormHeader />
            </Content>
          </Container>
        </WidthWrapper>
        <BackgroundImage />
      </Wrapper>
      <RelativeWrapper>
        <WidthWrapper>
          <Cards IconCardOne={IconCardOne} IconCardTwo={IconCardTwo} IconCardThree={IconCardThree} cardsTitle={cardsTitle} cards={cards} />
        </WidthWrapper>
      </RelativeWrapper>
    </>
  );
};

export default Header;